<template>
  <BTableCard
    :searchActive="true"
    :newAddActive="false"
    :totalActive="true"
    :backActive="backTo"
    :show="show"
    :icon="'fad fa-receipt'"
    :title="'Siparisler'"
    :total="total"
  >
    <template v-slot:headerAction>
      <div>
        <b-button
          squared
          variant="warning"
          class="icon btn-icon mr-1"
          @click="filtered = !filtered"
          v-b-tooltip.hover="'Filtrele'"
        >
          <i class="fad fa-filter" />
        </b-button>
        <b-button squared variant="success" v-if="!backTo" @click="handlerAddNew" v-b-tooltip.hover="'Yeni Kayıt'">
          <i class="fad fa-plus" /> YENİ KAYIT
        </b-button>
        <b-button squared variant="secondary" v-else @click="handlerBackTo" v-b-tooltip.hover="'Geri'">
          <i class="fad fa-angle-left" /> GERİ
        </b-button>
      </div>
    </template>
    <template v-slot:body>
      <div class="p-2">
        <transition mode="out-in">
          <router-view
            @total="total = $event"
            :filtered="filtered"
            @filtered="handlerFiltered($event)"
            @show="show = $event"
            @backTo="backTo = $event"
          />
        </transition>
      </div>
    </template>
  </BTableCard>
</template>
<script>
import store from '@/store';
import { useRouter } from '@/libs/utils';
import BTableCard from '@/components/cards/BTableCard.vue';
import { computed, defineComponent, ref, onMounted, watch } from '@vue/composition-api';

export default defineComponent({
  components: {
    BTableCard,
  },
  setup() {
    const expo = {};
    const { route, router } = useRouter();
    expo.show = ref(false);
    expo.filtered = ref(false);
    expo.backTo = ref(false);
    expo.total = ref(0);

    onMounted(async () => {
      expo.show.value = true;
      expo.backTo.value = false;
      expo.show.value = false;
    });

    expo.handlerFiltered = (event) => {
      expo.filtered.value = event;
    };

    expo.handlerAddNew = () => {
      expo.total.value = 0;
      expo.backTo.value = true;
      route.value.name != 'siparis-ekle' && router.push({ name: 'siparis-ekle' });
    };

    expo.handlerBackTo = () => {
      expo.backTo.value = false;
      router.push({ name: 'siparis-listele' });
    };

    watch(route, (val) => {
      if (val.name == 'siparis-listele') expo.backTo.value = false;
    });
    return { ...expo };
  },
});
</script>

<style lang="scss" scoped>
table.vgt-table {
  font-size: 14px;
  thead {
    background-color: rgba(#000, 0.07);
  }
  td {
    padding: 3px 0px 0px 5px !important;
    vertical-align: middle !important;
  }
}
</style>
